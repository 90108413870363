import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { graphql } from 'gatsby';
import { default as BaseImg } from 'gatsby-image';
import { Typography } from 'antd';

import SEO from '../components/SEO';
import MainContentWrapper from '../components/MainContentWrapper';

const { Title, Paragraph } = Typography;

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Column = styled.div`
  flex-basis: 100%;
  max-width: 100%;
  padding: 0;
  ${({ theme }) => `
    ${theme.breakPoints.up('sm')} {
      flex-basis: 50%;
      max-width: 50%;
      padding: 0 0.4rem;
    }
    
    ${theme.breakPoints.up('md')} {
      flex-basis: 25%;
      max-width: 25%;
    }
  `}
`;

const Img = styled(({ heights, ...props }) => <BaseImg {...props} />)`
  margin-top: 1rem;
  vertical-align: middle;
  ${({ heights }) => `height: ${heights.default || '30rem'};`}

  ${({ heights, theme }) => `
    ${theme.breakPoints.up('xxs')} {
      height: ${heights.xxs || '40rem'};
    }
    ${theme.breakPoints.up('sm')} {
      height: ${heights.sm};
    }
  `};
`;

const Gallery = ({ data }) => {
  const images = useMemo(() => {
    return [
      ...data.gallaryImagesHorizontal.nodes,
      ...data.gallaryImagesVertical.nodes,
    ].sort((a, b) => a.name.localeCompare(b.name));
  }, [data.gallaryImagesHorizontal.nodes, data.gallaryImagesVertical.nodes]);

  // numberOfChucks can be thought of number of columns
  const getChunks = useCallback((list, numberOfChucks) => {
    const final = [];
    let remainderOfList = list.length % numberOfChucks;
    const numberOfItemsPerChunk =
      (list.length - remainderOfList) / numberOfChucks;

    for (
      let i = 0;
      i < list.length - (list.length % numberOfChucks);
      i += numberOfItemsPerChunk
    ) {
      final.push(list.slice(i, i + numberOfItemsPerChunk));
      if (remainderOfList > 0) {
        final[final.length - 1].push(list[list.length - remainderOfList]);
        remainderOfList -= 1;
      }
    }
    return final;
  }, []);

  const imagesColumns = getChunks(images, 4);

  return (
    <>
      <SEO
        seo={{
          title: 'Gallery',
          metas: [
            {
              name: 'description',
              content: 'DRD event decorations gallery',
            },
          ],
        }}
      />
      <MainContentWrapper>
        <Title level={2} underline>
          Event Images
        </Title>
        <Paragraph strong>
          Here are some images of recent events we have designed.
        </Paragraph>
        <ImagesContainer>
          {imagesColumns.map(column => {
            return (
              <Column key={uuidv4()}>
                {column.map(image => {
                  return (
                    <Img
                      fluid={image.childImageSharp.fluid}
                      key={image.childImageSharp.id}
                      heights={
                        data.imagesHeights.frontmatter.heights[image.name]
                      }
                    />
                  );
                })}
              </Column>
            );
          })}
        </ImagesContainer>
      </MainContentWrapper>
    </>
  );
};

export const query = graphql`
  query gallaryImagesQuery {
    gallaryImagesHorizontal: allFile(
      filter: { relativeDirectory: { eq: "event-images" } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 500, jpegQuality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
          original {
            height
          }
          id
        }
      }
    }
    gallaryImagesVertical: allFile(
      filter: { relativeDirectory: { eq: "event-images/vertical-images" } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 600, maxHeight: 700, jpegQuality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
          original {
            height
          }
          id
        }
      }
    }
    imagesHeights: markdownRemark(
      frontmatter: { type: { eq: "image-grid-heights" } }
    ) {
      frontmatter {
        heights {
          event_00001 {
            sm
            xxs
          }
          event_00002 {
            sm
            xxs
            default
          }
          event_00003 {
            sm
          }
          event_00004 {
            sm
            xxs
            default
          }
          event_00005 {
            sm
            xxs
            default
          }
          event_00006 {
            sm
            xxs
            default
          }
          event_00007 {
            sm
            xxs
            default
          }
          event_00008 {
            sm
          }
          event_00009 {
            sm
            xxs
            default
          }
          event_00010 {
            sm
          }
          event_00011 {
            sm
          }
          event_00012 {
            sm
          }
          event_00013 {
            sm
            xxs
            default
          }
          event_00014 {
            sm
          }
          event_00015 {
            sm
            xxs
            default
          }
          event_00016 {
            sm
            xxs
            default
          }
          event_00017 {
            sm
          }
          event_00018 {
            sm
            xxs
            default
          }
          event_00019 {
            sm
            xxs
            default
          }
          event_00020 {
            sm
            xxs
            default
          }
          event_00021 {
            sm
            xxs
            default
          }
          event_00022 {
            sm
          }
          event_00023 {
            sm
            xxs
            default
          }
          event_00024 {
            sm
          }
        }
      }
    }
  }
`;

export default Gallery;
